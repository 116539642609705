// iconsMapping.js
import { HiOutlineDevicePhoneMobile } from "react-icons/hi2";
import { MdOutlineWeb } from "react-icons/md";
import { FaBrain } from "react-icons/fa6";
import { MdBuild } from "react-icons/md";
import { MdHelpOutline } from "react-icons/md";
import { MdAutorenew } from "react-icons/md";
import { FaTruckField } from "react-icons/fa6";
import { MdDesignServices } from "react-icons/md";


import { MdOutlineVideocam } from "react-icons/md";
import { MdOutlinePhotoCamera } from "react-icons/md";
import { MdEventAvailable } from "react-icons/md";
import { MdMovieEdit } from "react-icons/md";
import { CiStreamOn } from "react-icons/ci";
import { MdOutlineCampaign } from "react-icons/md";


const iconMapping = {
  appDevelopment: HiOutlineDevicePhoneMobile,
  siteDevelopment: MdOutlineWeb,
  customApplications: MdBuild,
  helpDeskSupport: MdHelpOutline,
  techOnSiteServices: FaTruckField,
  automationSolutions: MdAutorenew,
  artificialIntelligence: FaBrain,
  branding: MdDesignServices,

  photo:MdOutlinePhotoCamera,
  video: MdOutlineVideocam,
  event: MdEventAvailable,
  campaign: MdOutlineCampaign,
  production: MdMovieEdit,
  stream: CiStreamOn,
  // Add more mappings as needed
};

export default iconMapping;
