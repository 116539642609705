import React from 'react';
import { Box, Container, IconButton, Typography } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

class VideoSlider extends React.Component {
    sliderRef = React.createRef();
    observer = null; // For Intersection Observer

    constructor(props) {
        super(props);
        this.state = {
            videos: [],
            isLoading: true,
        };
    }

    componentDidMount() {
        this.fetchPlaylistItems();
        // Initialize Intersection Observer
        this.observer = new IntersectionObserver(this.handleObserver, { root: null, rootMargin: '0px', threshold: 0.1 });
    }

    fetchPlaylistItems = async () => {
        const { playlistId, apiKey } = this.props;
        const YOUTUBE_PLAYLIST_ITEMS_API = 'https://www.googleapis.com/youtube/v3/playlistItems';
        try {
            const response = await fetch(`${YOUTUBE_PLAYLIST_ITEMS_API}?part=snippet&playlistId=${playlistId}&key=${apiKey}&maxResults=50`);
            if (!response.ok) {
                throw new Error('Failed to fetch playlist items');
            }
            const data = await response.json();
            this.setState({ videos: data.items.map(item => ({ ...item, isVisible: false })), isLoading: false }, this.observeVideos);
        } catch (error) {
            console.error(error);
            this.setState({ isLoading: false });
        }
    };

    handleObserver = (entries) => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                const lazyVideo = entry.target.getAttribute('data-video-id');
                this.setState(prevState => ({
                    videos: prevState.videos.map(video =>
                        video.snippet.resourceId.videoId === lazyVideo ? { ...video, isVisible: true } : video
                    )
                }));
                this.observer.unobserve(entry.target);
            }
        });
    };

    observeVideos = () => {
        // Attach observer to each video container
        const videoElements = document.querySelectorAll('[data-video-id]');
        videoElements.forEach(element => this.observer.observe(element));
    };
    scrollLeft = () => {
        if (this.sliderRef.current) {
            // Smoothly scroll by a certain amount to the left
            this.sliderRef.current.scrollBy({ left: -384, behavior: 'smooth' });
        }
    };
    
    scrollRight = () => {
        if (this.sliderRef.current) {
            // Smoothly scroll by a certain amount to the right
            this.sliderRef.current.scrollBy({ left: 384, behavior: 'smooth' });
        }
    };

    renderVideos = () => {
        const { videos } = this.state;
        return videos.map((video, index) => (
            <Box
                key={index}
                data-video-id={video.snippet.resourceId.videoId} // Unique identifier for the observer
                sx={{
                    width: '384px',
                    marginRight: 2,
                    borderRadius: '12px',
                }}
            >
                {video.isVisible ? (
                    <iframe
                        width="384px"
                        height="100%"
                        src={`https://www.youtube.com/embed/${video.snippet.resourceId.videoId}`}
                        title={video.snippet.title}
                        frameBorder="0"
                        style={{ borderRadius: '12px', aspectRatio: '16 / 9' }}
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    ></iframe>
                ) : (
                    <Box sx={{ width: '300px', height: '169px', bgcolor: 'grey.300', borderRadius: '12px' }} /> // Placeholder
                )}
            </Box>
        ));
    };

    render() {
        const { isLoading } = this.state;
        if (isLoading) {
            return <Typography fontFamily='Pt Mono' color='white' >Loading...</Typography>;
        }

        return (
            <Box sx={{ width: '100%', backgroundColor: 'transparent' }}>
                {/* Slider Container */}
                <Box
                    ref={this.sliderRef}
                    sx={{
                        display: 'flex',
                        overflowX: 'scroll',
                        scrollBehavior: 'smooth',
                        p: 1,
                        '&::-webkit-scrollbar': {
                            display: 'none',
                        },
                        // Apply border-radius to the slider container if needed
                        borderRadius: '12px',
                        // Set a background for the slider container if needed
                        bgcolor: 'transparent',
                    }}
                >
                    {this.renderVideos()}
                </Box>
                {/* Controls Container */}
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center', // Center the controls
                        justifyContent: 'center', // Center the controls
                        marginTop: 2, // Add some space above the controls
                    }}
                >
                    <IconButton onClick={this.scrollLeft} sx={{ color: 'white', marginX: 1 }}>
                        <ArrowBackIosIcon />
                    </IconButton>
                    <Typography color='white' variant="h6" fontFamily='PT Mono' fontSize='0.8rem' > Scroll using the arrows </Typography>
                    <IconButton onClick={this.scrollRight} sx={{ color: 'white', marginX: 1 }}>
                        <ArrowForwardIosIcon />
                    </IconButton>
                </Box>
            </Box>
        );
    }
}

export default VideoSlider;






