// Contact.js
import React, { Component } from 'react';
import { Container, Grid, Typography, TextField, Button, Box } from '@mui/material';
import { Facebook, Instagram, LinkedIn, YouTube, Twitter, WhatsApp } from '@mui/icons-material';
import FadeInSection from '../useInView';

import { Helmet } from "react-helmet-async";

const textfieldStyle = {
  color: 'white',
  background: 'transparent',
  border: '2px solid white',
  borderRadius: '20px',
  fontFamily: 'Pt Mono, cursive',
  fontWeight: 'bold',
  marginBottom: '20px',
  alignItems:'center',
  justifyContent:'center'
};

const inputtextfieldStyle = {
  color: 'white',
  border: '2px solid white',
  borderRadius: '20px',
  fontFamily: 'Pt Mono, cursive',
  fontWeight: 'bold',
  alignItems:'center',
  justifyContent:'center'
};

const fieldStyles = {
  color: 'white',
  fontFamily: 'Pt Mono, cursive',
  fontWeight: 'bold',
  alignItems:'center',
  justifyContent:'center',
  padding:'2px'
};


class Contact extends Component {
  state = {
    fullName: '',
    email: '',
    phone: '',
    companyName: '',
    message: '',
  };

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    // Here you would use Axios to send the form data
    // Example: axios.post('YOUR_BACKEND_ENDPOINT', this.state)
    console.log(this.state); // For demonstration purposes
  };

  render() {

    return (
      <Container sx={{ width: '100%', height: '100%'}}>
        <Helmet>
          <title>Contact Us - Robotics Media</title>
          <meta name="description" content="Get in Touch with us!" />
          <meta name="keywords" content="Robotics Media, IT solutions, graphic design, photography, videography, digital services, brand, innovate, impress, branding, bristol, kingswood, england, romania, galati" />
        </Helmet>
        <Box style={{ height: '150px' }} />
        <Box sx={{ width: '100%' }}>
          <FadeInSection>
            <Typography color='white' variant="h3" gutterBottom>
              Contact us
            </Typography>
          </FadeInSection>
          <Box sx={{ alignItems: 'center', justifyContent: 'center', width: '100%', flexDirection: 'column' }} >
            <Grid container spacing={4} sx={{ alignItems: 'center', justifyContent: 'center', height: '100%' }} >
              <Grid item xs={12} md={6} >
                <Box sx={{ alignItems: 'flex-start', justifyContent: 'center', width: '100%', flexDirection: 'column' }} >
                  <FadeInSection>
                    <Typography color='white' fontFamily='Pt Mono' variant="h6" textAlign='flex-start' gutterBottom>
                      Get in touch and let’s make things happen!
                    </Typography>
                    <Box sx={{ height: '150px' }} />
                    <Typography color='white' fontFamily='Pt Mono' variant="body1">+44 7949 628 183</Typography>
                    <Typography color='white' fontFamily='Pt Mono' variant="body1">contact@robotics-media.com</Typography>
                    <Box alignItems='flex-start' width='80%' mt={2}>
                      <a href="https://www.facebook.com/RoboticsMediaLTD" target="_blank" rel="noopener noreferrer" style={{ marginRight: '8px' }}>
                        <Facebook style={{ width: '50px', height: '50px', cursor: 'pointer', color: 'white' }} />
                      </a>
                      <a href="https://www.instagram.com/roboticsmedia/" target="_blank" rel="noopener noreferrer" style={{ marginRight: '8px' }}>
                        <Instagram style={{ width: '50px', height: '50px', cursor: 'pointer', color: 'white' }} />
                      </a>
                      <a href="https://api.whatsapp.com/send?phone=447949628183" target="_blank" rel="noopener noreferrer" style={{ marginRight: '8px' }}>
                        <WhatsApp style={{ width: '50px', height: '50px', cursor: 'pointer', color: 'white' }} />
                      </a>
                      <a href="https://www.linkedin.com/in/YourProfile" target="_blank" rel="noopener noreferrer" style={{ marginRight: '8px' }}>
                        <LinkedIn style={{ width: '50px', height: '50px', cursor: 'pointer', color: 'white' }} />
                      </a>
                      <a href="https://www.youtube.com/channel/UCWoVOLnvt0rReON-qYmVEeA" target="_blank" rel="noopener noreferrer" style={{ marginRight: '8px' }}>
                        <YouTube style={{ width: '50px', height: '50px', cursor: 'pointer', color: 'white' }} />
                      </a>
                      <a href="https://twitter.com/robotics_mediaU" target="_blank" rel="noopener noreferrer">
                        <Twitter style={{ width: '50px', height: '50px', cursor: 'pointer', color: 'white' }} />
                      </a>
                    </Box>
                  </FadeInSection>
                </Box>
              </Grid>
              <Grid item xs={12} md={6} textAlign='center'>
                <FadeInSection>
                  <form onSubmit={this.handleSubmit}>
                    <TextField
                      fullWidth
                      required
                      autoComplete
                      variant="outlined"
                      name="fullName"
                      label="Full Name"
                      value={this.state.fullName}
                      onChange={this.handleChange}
                      InputProps={{ style: inputtextfieldStyle }}
                      InputLabelProps={{ style: fieldStyles }}
                      style={textfieldStyle}
                    />
                    <TextField
                      fullWidth
                      required
                      autoComplete
                      variant="outlined"
                      name="email"
                      label="Email"
                      value={this.state.email}
                      onChange={this.handleChange}
                      InputProps={{ style: inputtextfieldStyle }}
                      InputLabelProps={{ style: fieldStyles }}
                      style={textfieldStyle}
                    />
                    <TextField
                      fullWidth
                      required
                      autoComplete
                      variant="outlined"
                      name="phone"
                      label="Phone"
                      value={this.state.phone}
                      onChange={this.handleChange}
                      InputProps={{ style: inputtextfieldStyle }}
                      InputLabelProps={{ style: fieldStyles }}
                      style={textfieldStyle}
                    />
                    <TextField
                      fullWidth
                      required
                      autoComplete
                      variant="outlined"
                      name="companyName"
                      label="Company Name"
                      value={this.state.companyName}
                      onChange={this.handleChange}
                      InputProps={{ style: inputtextfieldStyle }}
                      InputLabelProps={{ style: fieldStyles }}
                      style={textfieldStyle}
                    />
                    <TextField
                      fullWidth
                      required
                      variant="outlined"
                      name="message"
                      label="Share your thoughts, questions, or ideas with us."
                      multiline
                      rows={4}
                      value={this.state.message}
                      onChange={this.handleChange}
                      InputProps={{ style: inputtextfieldStyle }}
                      InputLabelProps={{ style: fieldStyles }}
                      style={textfieldStyle}
                    />
                    <Button type="submit" variant="contained" sx={{ borderRadius: '20px', alignContent:'center', alignItems:'center', backgroundColor: 'black', color: 'white', fontFamily: 'Pt Mono', width: '200px', '&:hover': { backgroundColor: 'grey' } }}>
                      Send
                    </Button>
                  </form>
                </FadeInSection>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Box sx={{height:'20vh'}} />
      </Container>
    );
  }
}

export default Contact;
