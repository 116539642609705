import React, { Component } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography, Divider, Box } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import iconMapping from '../Icons';

class ServiceAccordion extends Component {
    render() {
        // Destructuring props in class component
        const { title, description, icon } = this.props;
        const IconComponent = iconMapping[icon];

        return (
            <Accordion sx={{ backgroundColor: '#313030', color: 'white' }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />}>
                    <Box sx={{ display: 'flex', alignItems: 'center', width:'100%', flexDirection: 'row'}} >
                        <IconComponent
                            style={{
                                width: '46px',
                                height: '46px',
                                marginRight: '10px',
                                fill: 'white',
                                backgroundColor: 'rgba(255,255,255,0.3)',
                                padding: '5px',
                                borderRadius: '50%'
                            }}
                        />
                        <Typography
                            className='code-element'
                            variant="h6"
                            fontFamily='PT Mono'
                            fontWeight='bold'
                            fontSize='1.25rem'
                            alignItems='center'
                            justifyContent='center'
                        >
                            {title}
                        </Typography>
                    </Box>
                    <Divider sx={{ backgroundColor: 'white', marginLeft: 2, marginRight: 2 }} />
                </AccordionSummary>
                <AccordionDetails>
                    <Typography
                        className='code-element'
                        variant="body1"
                        fontFamily='PT Mono'
                        fontWeight='bold'
                        fontSize='1rem'
                    >
                        {description}
                    </Typography>
                </AccordionDetails>
            </Accordion>
        );
    }
}

export default ServiceAccordion;