import { Box, Typography, Container } from '@mui/material';
import React, { Component } from 'react'
import PlaylistCarousel from './YoutubeCarousel';
const apiKey = 'AIzaSyAEnHVPydzfx7PucMciCbeFId5Gv6me64A'; // Replace with your actual API key
const playlistId = 'PLz32qEClgpKSuQJmiL3GPcKzcKSVWCL4s'; // Replace with your actual Playlist ID
class Portofolio extends Component {
  render() {
    return (
      <Box sx={{ backgroundColor: 'transparent', height: '100%' }} >
        <Box >
          <Box sx={{ height: '150px' }} />
          <Typography variant="h2" color='white' align="center"> Portofolio </Typography>
          <br/><br/>
          <Container maxWidth='lg' >
            <PlaylistCarousel apiKey={apiKey} playlistId={playlistId} />
          </Container>
        </Box>
      </Box>
    )
  }
}
export default Portofolio;
