import { Container, Box, Typography } from '@mui/material';
import React, { Component } from 'react'
import PaperGenerator from './BlogPostComponent';

class Blog extends Component {
  render() {
    return (
      <Container maxWidth='lg'  maxHeight='100vh' >
        <Box sx={{ height: '150px' }} />
        <Typography variant="h2" color='white' align="flex-stat"> Blog </Typography>
        <br /><br />
        <PaperGenerator number={6} />
      </Container>
    )
  }
}

export default Blog;