import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css'; // Your CSS imports
import App from './App';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import './reset.css'
// Define your theme, including the typography settings for your custom font
const theme = createTheme({
  typography: {
    // Use Neon Glow as the primary font, but include PT Mono for fallback or specific uses
    fontFamily: "'Neon Glow', 'PT Mono', Arial, sans-serif",
    h1: {
      fontSize: '4.5rem',
    },
    h2: {
      fontSize: '4rem', // Adjust as needed
    },
    h3: {
      fontSize: '3.5rem', // Adjust as needed
    },
    h4: {
      fontSize: '3rem', // Adjust as needed
    },
    h5: {
      fontSize: '2.5rem', // Adjust as needed
    },
    h6: {
      fontSize: '1.5rem', // Adjust as needed
    },
    subtitle1: {
      fontSize: '1.125rem', // Adjust as needed
    },
    subtitle2: {
      fontSize: '1rem', // Adjust as needed
    },
    body1: {
      fontSize: '1rem', // Adjust as needed
    },
    body2: {
      fontSize: '0.875rem', // Adjust as needed
    },
    button: {
      fontSize: '0.875rem', // Adjust as needed
    },
    caption: {
      fontSize: '0.75rem', // Adjust as needed
    },
    overline: {
      fontSize: '0.625rem', // Adjust as needed
    },
  },
  components: {
    // Example: specifically target MUI Typography component or others as needed
    MuiTypography: {
      styleOverrides: {
        root: {
          // Apply PT Mono specifically to elements using <Typography variant="code"> or similar
          '&[variant="code"], &[variant="body1"]': {
            fontFamily: "'PT Mono', monospace",
          },
        },
      },
    },
  },
  // Add any additional theme customizations here
});

// Use createRoot to initialize your application
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </React.StrictMode>
);
