import React, { Component } from 'react'
import { Box, Typography, Container, Divider } from '@mui/material';
import ServiceCard from './ServiceCard';

import { Helmet } from 'react-helmet-async';
import FadeInSection from '../useInView';

const services = [
    {
        icon: 'appDevelopment',
        title: 'App Development',
        description: 'We build custom mobile and web applications that meet your business needs, ensuring a seamless and engaging user experience across all platforms.',
    },
    {
        icon: 'siteDevelopment',
        title: 'Site Development',
        description: 'From responsive website designs to complex e-commerce platforms, we create online spaces that reflect your brand, engage your audience, and drive conversions.',
    },
    {
        icon: 'customApplications',
        title: 'Custom Applications',
        description: 'Tailored software solutions that address your unique challenges, improve operational efficiency, and give you a competitive edge in your industry.',
    },
    {
        icon: 'helpDeskSupport',
        title: 'Help Desk Support',
        description: 'Our dedicated support team is available to assist with any technical queries or challenges, ensuring your operations run smoothly and efficiently.',
    },
    {
        icon: 'techOnSiteServices',
        title: 'Tech On-Site Services',
        description: 'For issues that require a hands-on approach, our skilled technicians are ready to provide on-site assistance, ensuring minimal downtime and optimal performance.',
    },
    {
        icon: 'automationSolutions',
        title: 'Automation Solutions',
        description: 'Streamline your processes and increase efficiency with our automation services, reducing manual effort and freeing up your team to focus on strategic tasks.',
    },
    {
        icon: 'artificialIntelligence',
        title: 'Artificial Intelligence (AI)',
        description: 'Leverage the power of AI to gain valuable insights, automate tasks, and improve decision-making, enhancing your business operations and customer experience.'
    }
];

const designs = [
    {
        icon: 'branding',
        title: 'Brand Identity Design',
        description: 'Crafting a unique identity for your brand that resonates with your audience and stands out in the marketplace.'
    },
    {
        icon: 'branding',
        title: 'Logo Design',
        description: 'Creating memorable logos that encapsulate the essence of your brand and become the face of your business.'
    },
    {
        icon: 'branding',
        title: 'Graphic Design',
        description: 'From brochures and business cards to banners and packaging, our graphic design services cover all your needs with creativity and precision.'
    },
    {
        icon: 'branding',
        title: 'Digital Design',
        description: 'Designing compelling digital assets, including website graphics, social media content, and email marketing templates, that engage and convert.'
    },
    {
        icon: 'branding',
        title: 'UI/UX Design',
        description: 'Developing user interfaces and user experiences that are not only beautiful but also intuitive, enhancing the usability and effectiveness of your digital products.'
    }
]

const medias = [
    {
        icon: 'video',
        title: 'Video Production',
        description: 'Crafting compelling video content, from concept to completion, including corporate videos, promotional materials, and documentaries.'
    },
    {
        icon: 'photo',
        title: 'Photography',
        description: `Capturing moments with precision and beauty, whether it's for events, product photography, or brand imagery.`
    },
    {
        icon: 'event',
        title: 'Event Coverage',
        description: 'Offering comprehensive media coverage for events, ensuring every key moment is captured and presented in the best light.'
    },
    {
        icon: 'campaign',
        title: 'Marketing Campaign Visuals',
        description: 'Creating engaging visuals that support your marketing campaigns, driving engagement and enhancing brand visibility.'
    },
    {
        icon: 'production',
        title: 'Post-Production',
        description: `Expert editing, color grading, and audio mixing services that refine and perfect your content, ensuring it's ready for your audience.`
    },
    {
        icon: 'stream',
        title: 'Live Streaming',
        description: 'Delivering high-quality live streaming services for events, enabling you to reach a wider audience online in real-time.'
    }

]

class Services extends Component {
    render() {
        return (
            <Container style={{ paddingTop: '20px', paddingBottom: '20px', color: 'white', height: '100%' }}>
                <Helmet>
                    <title>Services - Robotics Media</title>
                    <meta name="description" content="Discover comprehensive services at Robotics Media: Expert IT solutions, cutting-edge graphic design, and professional photography & videography tailored for businesses and corporate clients. Elevate your brand with our bespoke digital services designed to innovate and impress."/>
                </Helmet>
                <Box style={{ height: '150px' }} />
                <FadeInSection>
                <Typography variant="h2" style={{ textAlign: 'flex-start', marginBottom: '40px', color: 'white' }}>
                    Our Services
                </Typography>
                </FadeInSection>
                <Box id="target-1" style={{ margin: '40px 0', padding: '10px', borderRadius: '10px' }}>
                    <Box style={{ height: '100px' }} />
                    <FadeInSection>
                    <Typography variant="h3" style={{ color: 'white' }}>IT Wizards</Typography>
                    <Typography className='code-element' variant='code' fontSize='1.5rem' style={{ color: 'white' }}>
                        Unleash the Power of Technology
                    </Typography>
                    <br /><br />
                    <Typography className='code-element' variant='code' style={{ color: 'white' }}>
                        In the fast-paced digital world, staying ahead means leveraging the latest in technology with a touch of magic. That's where our IT Wizards step in. Specializing in the following areas, we craft cutting-edge software solutions designed to elevate your business. Our team doesn't just develop; we protect your digital realm like superheroes, ensuring your journey in the digital age is secure, innovative, and transformative. Partner with us to turn your technological challenges into victories.
                    </Typography>
                    <br /> <br />
                    <Typography className='code-element' variant='code' fontSize='1.5rem' style={{ color: 'white' }}>
                        Our Areas of Expertise:
                    </Typography>
                    </FadeInSection>
                    <FadeInSection>
                    <br /><br />
                    {services.map((service, index) => (
                        <Box key={index} mb={2}>
                            <ServiceCard  icon={service.icon} title={service.title} description={service.description} />
                        </Box>
                    ))}
                    </FadeInSection>
                    <br /> <br />
                    <FadeInSection>
                    <Typography className='code-element' variant='code' component='p' style={{ color: 'white' }}>
                        By choosing us, you're not just getting a service provider; you're gaining a partner dedicated to your success in the digital realm. With a comprehensive range of services from development to on-the-ground support, and now including advanced automation and AI solutions, we're here to ensure that your technological infrastructure is robust, reliable, and ready to meet the challenges and opportunities of the digital age.
                    </Typography>
                    </FadeInSection>
                    <br />
                    <FadeInSection>
                    <Typography className='code-element' variant='code' component='p' style={{ color: 'white' }}>
                        Let's embark on this journey together and transform your technological challenges into victories.
                    </Typography>
                    </FadeInSection>
                </Box>
                <Divider sx={{ height: '5px', borderRadius: '20px', backgroundColor: 'white' }} />
                <Box id="target-2" style={{ margin: '100px 0', padding: '10px', borderRadius: '10px' }}>
                    <Box style={{ height: '100px' }} />
                    <FadeInSection>
                    <Typography variant="h4" style={{ color: 'white' }}>Design Dreamers</Typography>
                    <Typography className='code-element' variant='code' fontSize='1.5rem' style={{ color: 'white' }}>
                        Bring Your Brand to Life
                    </Typography>
                    <br /><br />
                    <Typography className='code-element' variant='code' style={{ color: 'white' }}>
                        Imagine a place where creativity knows no bounds—a place where your brand is not just seen but truly experienced. Our Design Dreamers make this a reality. With a knack for identity wizardry, graphic magic, and inventive charm, we breathe life into brands, making each identity unforgettable. From logos that speak volumes to visuals that captivate, our designs don't just stand out; they tell a story. Let us weave the visual narrative of your brand and watch as it transcends the ordinary.
                    </Typography>
                    </FadeInSection>
                    <FadeInSection>
                    <br /><br />
                    {designs.map((design, index) => (
                        <Box key={index} mb={2}>
                            <ServiceCard icon={design.icon} title={design.title} description={design.description} />
                        </Box>
                    ))}
                    </FadeInSection>
                    <br /> <br />
                    <FadeInSection>
                    <Typography className='code-element' variant='code' component='p' style={{ color: 'white' }}>
                        By partnering with us, your brand will not only be seen—it will be remembered and cherished. Our Design Dreamers are ready to transform your vision into reality, creating a brand identity that is not only visually stunning but also deeply resonant with your target audience.
                    </Typography>
                    </FadeInSection>
                    <FadeInSection>
                    <br />
                    <Typography className='code-element' variant='code' component='p' style={{ color: 'white' }}>
                        Let's start the journey to make your brand unforgettable. Contact us today to explore how our creative solutions can elevate your brand beyond the ordinary.
                    </Typography>
                    </FadeInSection>
                </Box>
                <Divider sx={{ height: '5px', borderRadius: '20px', backgroundColor: 'white' }} />
                <Box id="target-3" style={{ margin: '40px 0', padding: '10px', borderRadius: '10px' }}>
                    <Box style={{ height: '100px' }} />
                    <FadeInSection>
                    <Typography variant="h4" style={{ color: 'white' }}>Media Masters</Typography>
                    <Typography className='code-element' variant='code' fontSize='1.5rem' style={{ color: 'white' }}>
                        Transform Ideas into Media Masterpieces
                    </Typography>
                    <br /><br />
                    <Typography className='code-element' variant='code' style={{ color: 'white' }}>
                        Enter the realm of Media Masters, where every piece of content is a journey through stunning visuals, timeless videos, and seamless event coverage. Our expertise in creating captivating content transforms your ideas into media masterpieces. Whether it's a corporate event that needs comprehensive coverage or a marketing campaign that demands engaging visuals, our team is equipped to bring your vision to life. With each frame, we capture the essence of your message, crafting content that not only resonates with your audience but leaves a lasting impression.
                    </Typography>
                    </FadeInSection>
                    <FadeInSection>
                    <br /><br />
                    {medias.map((media, index) => (
                        <Box key={index} mb={2}>
                            <ServiceCard icon={media.icon} title={media.title} description={media.description} />
                        </Box>
                    ))}
                    </FadeInSection>
                    <br /> <br />
                    <FadeInSection>
                    <Typography className='code-element' variant='code' component='p' style={{ color: 'white' }}>
                        By partnering with our Media Masters, you're ensuring that your ideas are not just seen but felt and remembered. Our team is passionate about bringing stories to life, creating content that not only meets but exceeds your expectations.
                    </Typography>
                    </FadeInSection>
                    <br />
                    <FadeInSection>
                    <Typography className='code-element' variant='code' component='p' style={{ color: 'white' }}>
                        Let us help you transform your ideas into media masterpieces. Contact us today to start your project and make an unforgettable impact with your audience.
                    </Typography>
                    </FadeInSection>
                </Box>
            </Container>
        )
    }
}

export default Services;