import React from 'react';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

const PaperGenerator = ({ number }) => {
  const papers = Array.from({ length: number }, (_, index) => (
    // Each Grid item is configured to take the full width on small screens
    // and divide the row into thirds on medium screens and above.
    // The content of each Paper is centered using flex.
    <Grid item xs={12} sm={12} md={4} key={index} sx={{ display: 'flex', justifyContent: 'center' }}>
      <Paper
        elevation={3}
        sx={{
          width: 300, // Fixed width
          height: 400, // Fixed height
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start', // Distributes spacing evenly
          alignItems: 'center', // Centers items horizontally
          overflow: 'hidden',
          borderRadius: '20px',
          borderColor: 'white',
          borderWidth:'1px',
          borderStyle:'solid',
          boxShadow: '0px 10px 20px rgba(255,255,255,0.2)',
          bgcolor:'rgba(0,0,0,0.5)',
        }}
      >
        {/* Photo */}
        <img
          src="https://via.placeholder.com/300x150"
          alt="Placeholder"
          style={{ width: '100%', height: '40%', objectFit: 'cover' }}
        />
        {/* Title */}
        <Typography variant="h6" color='white' component="h2" sx={{ textAlign: 'flex-start',width:'90%', margin: '16px' }}>
          Title {index + 1}
        </Typography>
        {/* Text */}
        <Typography variant="body1" fontFamily='Pt mono' color='white' sx={{ textAlign: 'flex-start', margin: '0 16px' }}>
          This is a short description text for Paper {index + 1}. It can be expanded to fit the content as needed.
        </Typography>
        {/* See More Button */}
        <Button variant="contained" sx={{ margin: '16px' }}>
          See More
        </Button>
      </Paper>
    </Grid>
  ));

  return (
    // The Grid container centers all items and allows for responsive adjustments.
    // Use of 'justifyContent: "center"' ensures that items are centered in the row,
    // especially when there's an odd number of items or if the total width doesn't fill the row.
    <Grid container spacing={4} justifyContent="center">
      {papers}
    </Grid>
  );
};

export default PaperGenerator;

